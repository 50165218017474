import { ComponentProps, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { MixModal } from "./MixModal";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import cookie from "cookie";
import dayjs from "dayjs";

const Container = styled.div`
  --main-img-width: 335px;
  --main-img-height: 359px;

  --pc-text-img-width: 296px;
  --pc-text-img-height: 199px;
  --pc-text-box-padding: 28px 18px 24px;

  --mo-text-img-width: 276px;
  --mo-text-img-height: 135px;
  --mo-text-box-padding: 24px;

  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.palette.common.white.main};
  border-radius: 4px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.32);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainImgBox = styled.div`
  position: relative;
  width: var(--main-img-width);
  height: var(--main-img-height);
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--pc-text-box-padding);
  gap: 20px;

  @media (max-width: 768px) {
    padding: var(--mo-text-box-padding);
    gap: 26px;
  }
`;

const TextImgPc = styled.div`
  display: block;
  position: relative;
  width: var(--pc-text-img-width);
  height: var(--pc-text-img-height);

  @media (max-width: 768px) {
    display: none;
  }
`;

const TextImgMo = styled.div`
  position: relative;
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: var(--mo-text-img-width);
    height: var(--mo-text-img-height);
  }
`;

const MODAL_CONFIGS = {
  landing: {
    url: "https://chromewebstore.google.com/detail/%EB%AF%B9%EC%8A%A4mix-%EB%A7%88%EC%BC%80%ED%8C%85-%EC%9D%B8%EC%82%AC%EC%9D%B4%ED%8A%B8-%EB%AF%B8%EB%94%94%EC%96%B4/lmbbnpelmllcplpeelhghdbjjgcdickc",
    button: {
      text: "자세히 알아보기",
      bgColor: "#333333",
      bgHoverColor: "#212121",
    },
  },
  images: {
    main: "/img/modal/extension_img_250204.png",
    text: {
      pc: "/img/modal/extension_text_pc_250204.png",
      mobile: "/img/modal/extension_text_mo_250204.png",
    },
  },
} as const;

export const IntervalModal = ({
  startDate: $startDate,
  endDate: $endDate,
  interval,
  targetPages = undefined,
  isVisibleInExtension = false,
  ...props
}: {
  /**
   * 노출 주기 : N일 간격으로 모달 노출
   */
  interval: number;
  /**
   * 노출 시작일 : YYYY-MM-DD
   */
  startDate: string;
  /**
   * 노출 종료일 : YYYY-MM-DD (없으면 무기한 노출)
   */
  endDate?: string;
  /**
   * 특정 페이지에만 노출할 경우 사용 (없으면 모든 페이지에서 노출)
   */
  targetPages?: string[];
  /**
   * 크롬 확장프로그램 에서 표시할지 여부 (디폴트로 표시 안함)
   */
  isVisibleInExtension?: boolean;
} & Omit<ComponentProps<typeof MixModal>, "isOpen">) => {
  const theme = useTheme();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [mainImgLoaded, setMainImgLoaded] = useState(false);

  const today = useMemo(() => dayjs(), []);

  const startDate = useMemo(
    () => ($startDate ? dayjs($startDate) : dayjs()),
    [$startDate],
  );

  const endDate = useMemo(
    () => ($endDate ? dayjs($endDate) : null),
    [$endDate],
  );

  const handleBtnClick = () => {
    axios.post("/api/hide_modal", {
      interval,
    });
    setIsOpen(false);
  };

  useEffect(() => {
    const key = "mix/modal";
    const cookies = document.cookie;
    const exposed = cookie.parse(cookies)[key] === "true";

    const inRange =
      today.isAfter(startDate) && (!endDate ? true : today.isBefore(endDate));

    const isTargetPage = !targetPages
      ? true
      : targetPages.includes(router.pathname);

    const isEnabledInExtension =
      process.env.BUILD_TARGET !== "extension" || isVisibleInExtension;

    if (!exposed && inRange && isTargetPage && isEnabledInExtension) {
      setIsOpen(true);
    }
  }, [
    today,
    startDate,
    endDate,
    router.pathname,
    targetPages,
    isVisibleInExtension,
  ]);

  return (
    <MixModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      {...props}
    >
      <Container>
        <MainImgBox
          style={{
            backgroundColor: mainImgLoaded
              ? "transparent"
              : theme.palette.grey[100],
          }}
        >
          <Image
            alt="main image"
            src={MODAL_CONFIGS.images.main}
            fill={true}
            priority={true}
            onLoad={() => setMainImgLoaded(true)}
          />
        </MainImgBox>
        <TextBox>
          <TextImgMo>
            <Image
              alt="text mobile image"
              src={MODAL_CONFIGS.images.text.mobile}
              fill={true}
              priority={true}
            />
          </TextImgMo>
          <TextImgPc>
            <Image
              alt="text pc image"
              src={MODAL_CONFIGS.images.text.pc}
              fill={true}
              priority={true}
            />
          </TextImgPc>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 16px;
            `}
          >
            <Link
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 18px 10px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
                color: ${theme.palette.common.white.main};
                background-color: ${
                  today.isBefore("2024-07-17")
                    ? "#0095F7"
                    : MODAL_CONFIGS.landing.button.bgColor
                };
                  &:hover,
                  &[data-interaction="hover"] {
                    background-color: ${
                      MODAL_CONFIGS.landing.button.bgHoverColor
                    };};
                  }
              `}
              href={MODAL_CONFIGS.landing.url}
              onClick={handleBtnClick}
            >
              {today.isBefore("2024-07-17")
                ? "얼리버드 특가 확인하기"
                : MODAL_CONFIGS.landing.button.text}
            </Link>
            <span
              css={css`
                width: fit-content;
                font-size: 14px;
                font-weight: 500;
                line-height: 1;
                color: ${theme.palette.text.secondary};
                text-decoration-line: underline;
                cursor: pointer;
              `}
              onClick={handleBtnClick}
            >
              {interval === 1
                ? "오늘 하루 그만보기"
                : `${interval}일간 보지 않기`}
            </span>
          </div>
        </TextBox>
      </Container>
    </MixModal>
  );
};
